import { tenantApi } from './baseServerApi';
import { ICallsQuery } from 'interfaces/query.interface';
import { IApiCall } from 'interfaces/apiCall.interface';

export interface IQueryCallsTable {
    query_execution_id: string;
    query_execution_id_total: string;
}

export const queryPageApi = tenantApi.injectEndpoints({
    endpoints: (builder) => ({
        getCalls: builder.query<IQueryCallsTable, ICallsQuery>({
            query: (body: any) => ({
                url: 'calls/query',
                method: 'POST',
                body,
            }),
        }),
        initiateCallsQuery: builder.query<IQueryCallsTable, ICallsQuery>({
            query: (body: any) => ({
                url: 'calls/queries',
                method: 'POST',
                body,
            }),
        }),
        getCallsWithExecId: builder.query<IApiCall[], string>({
            query: (execId: string) => ({
                url: `calls/queries/${execId}`,
            }),
        }),
        getTotalNumCallsWithExecId: builder.query<{ total: number }, string>({
            query: (execId: string) => ({
                url: `queries/${execId}/total`,
            }),
        }),
    }),
});

export const {
    useLazyGetCallsWithExecIdQuery,
    useLazyGetTotalNumCallsWithExecIdQuery,
    useLazyGetCallsQuery,
    useLazyInitiateCallsQueryQuery,
} = queryPageApi;

import React, { useCallback, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Allotment } from 'allotment';
import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query';

import { BusinessImpact } from './business-impact/BusinessImpact';
import { EntityTimeline } from 'features/user/EntityTimeline/EntityTimeline';
import { SummaryDetails } from './summary-details/SummaryDetails';
import { EventTypesEnum } from 'enums/eventTypes.enum';
import Spinner from '../../sharedComponents/spinner/Spinner';
import { selectCurrentTenantKey, selectFeatureFlagMap } from 'api/slices/appInfoSlice';
import { AlertCallsTable } from 'features/user/AlertCallsTable/AlertCallsTable';
import { SingleCallDetails } from 'sharedComponents/SingleCallDetails/SingleCallDetails';
import { EntitySummaryWithChart } from 'features/user/EntitySummaryWithChart/EntitySummaryWithChart';
import { IApiCall } from 'interfaces/apiCall.interface';
import { IAlert } from 'interfaces/alert.interface';
import { useGetAlertInfoQuery, useGetMetadataQuery } from 'api/InvestigateApi';

import 'features/discovery/endpoint/Container.scss';
import './User.scss';

export interface IInvestigateQueryParams {
    entityType: string;
    eventType: EventTypesEnum;
    eventId: string;
    eventTimestamp: string;
}
export const typePathMap = {
    [EventTypesEnum.Alert]: 'alerts',
    [EventTypesEnum.Sequence]: 'sequences',
    [EventTypesEnum.Call]: 'calls',
};

export const User = () => {
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const featureflagMap = useSelector(selectFeatureFlagMap);
    const alertsOnly = new URLSearchParams(useLocation().search).get('alertsOnly') === 'true';
    const history = useHistory();
    const params: IInvestigateQueryParams = useParams();
    const { entityType, eventType, eventId, eventTimestamp } = params;
    const [emptyState, setEmptyState] = useState<boolean>(false);
    const [isBusinessImpactExpanded, setIsBusinessImpactExpanded] = useState(false);
    const [callsAttribute, setCallsAttribute] = useState<Record<string, IApiCall | IAlert>>();
    const isCall = eventType === EventTypesEnum.Call;
    const { data: alertInfo } = useGetAlertInfoQuery(isCall ? skipToken : eventId);
    const { data: deprecatedEventInfo } = useGetMetadataQuery({ eventType, eventId, eventTimestamp });
    const event = featureflagMap.athena ? callsAttribute?.[eventId] || alertInfo : deprecatedEventInfo;

    const [commentsCount, setCommentsCount] = useState<any>();

    const onCommentCountChange = (comments_count: number) => {
        setCommentsCount(comments_count);
    };

    const onTimelineClickedHandler = useCallback(
        (type?: EventTypesEnum, id?: string, timestamp?: number) => {
            if (type && id && timestamp) {
                setEmptyState(false);
                return history.push(
                    `/${currentTenantKey}/entity/${entityType}/event/${type}/${id}/${timestamp}/?alertsOnly=${alertsOnly}`
                );
            }
            setEmptyState(true);
        },
        [currentTenantKey, entityType, alertsOnly]
    );

    function alertsOnlyChecked(checked: boolean) {
        if (!checked) {
            setEmptyState(false);
        }
        history.push(
            `/${currentTenantKey}/entity/${entityType}/event/${eventType}/${eventId}/${eventTimestamp}?alertsOnly=${checked}`
        );
    }

    return (
        <div className="user-container container">
            <EntitySummaryWithChart />
            <div className="content-container">
                <Allotment vertical={false} defaultSizes={[550, 1500]}>
                    <Allotment.Pane minSize={550} maxSize={1000}>
                        <div className="timeline">
                            <EntityTimeline
                                onItemClicked={onTimelineClickedHandler}
                                alertsOnly={alertsOnly}
                                onAlertsOnlyChecked={alertsOnlyChecked}
                                setCallsAttribute={setCallsAttribute}
                            />
                        </div>
                    </Allotment.Pane>
                    <Allotment.Pane>
                        <div className="master-detail">
                            {!event ? (
                                <Spinner show />
                            ) : emptyState ? (
                                <span className="info-message">Select an event from the list on the left</span>
                            ) : (
                                <>
                                    <div className="detail-header">
                                        <SummaryDetails
                                            eventData={event}
                                            eventType={eventType}
                                            setExpanded={setIsBusinessImpactExpanded}
                                            expanded={isBusinessImpactExpanded}
                                            commentsCount={commentsCount}
                                        />
                                    </div>
                                    {isCall ? (
                                        <div className="info-container--event">
                                            <SingleCallDetails data={event as IApiCall} />
                                        </div>
                                    ) : (
                                        <>
                                            <div className="info-container">
                                                <BusinessImpact
                                                    onCommentCountChange={onCommentCountChange}
                                                    data={event}
                                                    isExpanded={isBusinessImpactExpanded}
                                                    toggleExpanded={() => setIsBusinessImpactExpanded((prev) => !prev)}
                                                />
                                            </div>
                                            <AlertCallsTable />
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </Allotment.Pane>
                </Allotment>
            </div>
        </div>
    );
};

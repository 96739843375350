import React, { Dispatch, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { isValidRow, QueryBuilder, validateRow } from './QueryBuilder/QueryBuilder';
import { UiButton } from 'sharedComponents/button/Button';
import { UiInput } from 'sharedComponents/UiInput/UiInput';
import { QueryResultsTable } from './QueryResultsTable/QueryResultsTable';
import { UiDatePicker } from 'sharedComponents/UiDatePicker/UiDatePicker';
import Spinner from 'sharedComponents/spinner/Spinner';
import { ActionType, AppStateContext, IAppState } from 'contexts/AppStateContext';
import { httpGet, PAGE_SIZE_LIMIT } from 'general/http-service';
import { errorMessage } from 'general/toast-service';
import { sortEntitiesByPrecedence } from 'general/utils';
import { IEndpointDisplay } from 'interfaces/endpoint.interface';
import { ICallFilter, ICallsQuery, IQueryRow, IQueryValidation } from 'interfaces/query.interface';
import { LabelValue } from 'interfaces/labels.interface';
import { selectCurrentTenantKey, selectFeatureFlagMap } from 'api/slices/appInfoSlice';
import { fitToMaxSupportedDateRange, TDatetimeRange } from 'sharedComponents/shared/UiChronoRangePicker/utils';
import { IEntity } from 'api/entitiesApi';
import { EntitySelect } from 'sharedComponents/EntitySelect/EntitySelect';
import {
    useLazyGetCallsQuery,
    useLazyGetCallsWithExecIdQuery,
    useLazyGetTotalNumCallsWithExecIdQuery,
    useLazyInitiateCallsQueryQuery,
} from 'api/queryPageApi';

import './Query.scss';

export const QUERY_RESULT_LIMIT = 300;
export const ENDPOINTS_RESULT_LIMIT = PAGE_SIZE_LIMIT;
let queryItems: IQueryRow[] = [];

export interface IEndpointsDataState {
    endpoints: IEndpointDisplay[];
    endpointsIds: string[];
}

const numbersOperatorsKeys = ['Eq', 'Ne', 'Gt', 'Ge', 'Lt', 'Le'];

const getConditionTypeFromOperator = (operator: string) => {
    const isInt = numbersOperatorsKeys.includes(operator);
    return isInt ? 'Integer' : 'String';
};

export const Query = () => {
    const location = useLocation();
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const featureflagMap = useSelector(selectFeatureFlagMap);
    const { state }: { state: IAppState; dispatch: Dispatch<ActionType> } = useContext(AppStateContext);
    const [queryBuilderSpinner, setQueryBuilderSpinner] = useState<boolean>(true);
    const [queryInProgress, setQueryInProgress] = useState<boolean>(false);
    const queryParams: any = new URLSearchParams(location.search);
    const endpointsIds: any[] = queryParams.get('endpointIds') ? JSON.parse(queryParams.getAll('endpointIds')) : [];
    const paramsEntityType: string = queryParams.get('entityType') || '';
    const paramsEntityId: string = queryParams.get('entityId') || '';
    const eventTimestamp: string = queryParams.get('timestamp') || '';
    const [endpointsData, setEndpointsData] = useState<IEndpointsDataState>({
        endpoints: [],
        endpointsIds: [],
    });
    const [entityIdFromInput, setEntityIdFromInput] = useState<string>('');
    const queryRequest = useRef<any>({});
    const [timeRangeFromInput, setTimeRangeFromInput] = useState<[moment.Moment, moment.Moment] | [string, string]>([
        eventTimestamp ? moment(eventTimestamp) : moment().subtract(7, 'd'),
        eventTimestamp ? moment(eventTimestamp).add(7, 'd') : moment(),
    ]);

    // sort params
    const defaultSort = 'desc(timestamp)';

    const [entityTypeOptions, setEntityTypeOptions] = useState<LabelValue[]>([]);
    const [entityTypeFromInput, setEntityTypeFromInput] = useState<string>('');
    const [validation, setValidation] = useState<IQueryValidation>();
    const [triggerGetCalls] = useLazyGetCallsQuery();
    const [triggerInitiateGetCalls] = useLazyInitiateCallsQueryQuery();
    const [triggerCallsWithExec] = useLazyGetCallsWithExecIdQuery();
    const [triggerGetTotalNumCalls] = useLazyGetTotalNumCallsWithExecIdQuery();

    useEffect(() => {
        if (!entityTypeFromInput && entityTypeOptions.length) {
            setEntityTypeFromInput(entityTypeOptions[0].value);
        }
    }, [entityTypeOptions.length]);

    const [queryFilter, setQueryFilter] = useState({
        timeRange: timeRangeFromInput,
        entityType: entityTypeFromInput,
        entityId: entityIdFromInput,
    });

    const queryBuilderChanged = (data: any) => {
        queryItems = data;
    };

    /**
     * Check query builder input validation
     */
    function validate(queryItems: any): IQueryValidation {
        if (queryItems.length === 0) {
            const rows = [
                {
                    endpoint: 'This field required',
                    conditions: [],
                },
            ];

            return { rows };
        }

        const rows = queryItems.map((queryItem: any) => validateRow(queryItem));

        return { rows };
    }

    const getQueryObject = (sortParams: string, offset: number) => {
        const callFilters: ICallFilter[] = [];
        // Convert "queryItems" to "callFilters"
        queryItems.forEach((queryItem) => {
            const callFilter: ICallFilter = {
                attribute_filters: [],
            };
            // If an Endpoint is selected - Populate the "attribute_filters"
            // with the "endpoints" info - Method , Parameterised Path, Service
            // If Any is selected - send request without the 3 attributes
            if (queryItem.defaultValue !== 'any_endpoint') {
                callFilter.attribute_filters.push(
                    {
                        name: 'Method',
                        value: queryItem.endpointMethod,
                        operator: 'Is',
                        in: 'Headers',
                        part_of: 'Request',
                        value_type: 'String',
                    },
                    {
                        name: 'Parameterised Path',
                        value: queryItem.endpointPath,
                        operator: 'Is',
                        in: 'Enrichment',
                        part_of: 'Attributes',
                        value_type: 'String',
                    },
                    {
                        name: 'Service',
                        value: queryItem.endpointServiceName,
                        operator: 'Is',
                        in: 'Enrichment',
                        part_of: 'Attributes',
                        value_type: 'String',
                    }
                );
            }

            // Populate the "attribute_filters" with the "conditions" info
            queryItem.conditions.forEach((condition: any) => {
                // convert OneOf / NotOneOf string array to string with single quotes around each value and , as delimiter
                if (condition.operator === 'OneOf' || condition.operator === 'NotOneOf') {
                    let tempVal = '';
                    condition.value.forEach((value: string, index: number) => {
                        tempVal += `'${value.replace(/'/g, "\\'")}'`; // escape single quotes
                        tempVal = index < condition.value.length - 1 ? tempVal + ',' : tempVal;
                    });
                    condition.value = tempVal;
                }

                callFilter.attribute_filters.push({
                    name: condition.name,
                    value: condition.value,
                    operator: condition.operator,
                    in: condition.in,
                    part_of: condition.partOf,
                    value_type: getConditionTypeFromOperator(condition.operator),
                });
            });
            callFilters.push(callFilter);
        });

        // Create the query object, typed depending on the amount of callFilters (query endpoints)
        let query;

        if (callFilters.length === 0) {
            // query builder is empty -> returning without making post req
            return null;
        }
        query = {} as ICallsQuery;
        query.call_filter = callFilters[0];
        query.entity_filter = { name: queryFilter.entityType || '', value: queryFilter.entityId || '' };

        const from = queryFilter.timeRange[0] ? queryFilter.timeRange[0].valueOf() : 0;
        const to = queryFilter.timeRange[1] ? queryFilter.timeRange[1].valueOf() : Date.now();

        query.time_range = { from: from as number, to: to as number };
        query.offset = offset;
        query.limit = QUERY_RESULT_LIMIT;
        query.sort_by = sortParams.split('=')[1] || defaultSort;
        queryRequest.current = query;

        return query;
    };

    const onClearAll = () => {
        setEntityIdFromInput('');
        setTimeRangeFromInput(['', '']);
        queryRequest.current = {};
        queryItems = [];
    };

    const onDateChangeHandler = (
        range: TDatetimeRange,
        rangeFormat: [string, string],
        info: { range: 'start' | 'end' }
    ) => {
        const normalizedDateRange = fitToMaxSupportedDateRange(range, info, 14);
        normalizedDateRange ? setTimeRangeFromInput(normalizedDateRange) : setTimeRangeFromInput(['', '']);
    };

    useEffect(() => {
        httpGet(`organizations/${currentTenantKey}/entities`).then((res) => {
            if (res.data?.items) {
                const sortedEntitiesDropdownOptions = sortEntitiesByPrecedence(res.data.items).map(
                    (entityType: IEntity) => ({
                        value: entityType.name,
                        label: entityType.name,
                    })
                );
                setEntityTypeOptions(sortedEntitiesDropdownOptions);
                // if no endpoint IDs were selected in url, we are done loading
                !endpointsIds.length && setQueryBuilderSpinner(false);
            }
        });
    }, [currentTenantKey]);

    useEffect(() => {
        let offset = 0;
        let totalEndpointsToFetch: number = 0;

        queryRequest.current = {};
        queryItems = [];
        if (!entityTypeFromInput) {
            setEntityTypeFromInput(paramsEntityType);
        }
        setEntityIdFromInput(paramsEntityId);
        setQueryBuilderSpinner(true);

        const from_timestamp = moment().subtract(1, 'years').format('X');
        const to_timestamp = moment().format('X');
        httpGet(`organizations/${currentTenantKey}/discovery/endpoints`, {
            limit: ENDPOINTS_RESULT_LIMIT.toString(),
            from_timestamp,
            to_timestamp,
        })
            .then((res: any) => {
                totalEndpointsToFetch = res.data.total;
                setEndpointsData({ endpoints: res.data.items, endpointsIds });
                setQueryBuilderSpinner(false);
                if (offset + ENDPOINTS_RESULT_LIMIT <= totalEndpointsToFetch) {
                    getMoreEndpoints();
                }
            })
            .catch((err) => {
                errorMessage(err);
            });

        function getMoreEndpoints() {
            offset += ENDPOINTS_RESULT_LIMIT;
            const endpointUrl = `organizations/${currentTenantKey}/discovery/endpoints`;
            httpGet(endpointUrl, {
                offset: offset.toString(),
                limit: ENDPOINTS_RESULT_LIMIT.toString(),
                from_timestamp,
                to_timestamp,
            })
                .then((res) => {
                    setEndpointsData((prevData: IEndpointsDataState) => ({
                        ...prevData,
                        endpoints: [...prevData.endpoints, ...res.data.items],
                    }));

                    if (offset + ENDPOINTS_RESULT_LIMIT <= totalEndpointsToFetch) {
                        getMoreEndpoints();
                    }
                })
                .catch((err) => {
                    errorMessage(err);
                });
        }
    }, [currentTenantKey]);

    const getTableDataWithAthena = useCallback(
        async (startIdx: number, endIdx: number, sortParams: string) => {
            const queryPayload = getQueryObject(sortParams, startIdx) as ICallsQuery | null;
            if (!queryPayload) {
                return;
            }

            queryPayload.sort_by = sortParams ? sortParams.split('=')[1] : defaultSort;

            setQueryInProgress(true);

            return triggerInitiateGetCalls(queryPayload)
                .unwrap()
                .then(async (resExecIds: any) => {
                    const pollingCallListAndTotal = new Promise((resolve, reject) => {
                        const { query_execution_id, query_execution_id_total } = resExecIds;
                        let callListRes: any, totalCallsRes: any;
                        const startTime = new Date().getTime();

                        let intervalID = setInterval(async () => {
                            const windowLocation = document.location;
                            const isQueryPage = windowLocation.href.split('/').includes('query');

                            // stop polling after 2.5 min
                            if (!isQueryPage || new Date().getTime() - startTime > 150000) {
                                clearInterval(intervalID);
                                return resolve({ items: callListRes || [], total: totalCallsRes?.total || 0 });
                            }

                            try {
                                if (!callListRes) {
                                    callListRes = await triggerCallsWithExec(query_execution_id).unwrap();
                                }

                                if (!totalCallsRes) {
                                    totalCallsRes = await triggerGetTotalNumCalls(query_execution_id_total).unwrap();
                                }

                                if (callListRes && (totalCallsRes?.total || totalCallsRes?.total === 0)) {
                                    clearInterval(intervalID);
                                    return resolve({ items: callListRes || [], total: totalCallsRes?.total || 0 });
                                }
                            } catch (error: any) {
                                errorMessage(error?.data?.detail);
                                clearInterval(intervalID);
                                return resolve({ items: [], total: 0 });
                            }
                        }, 2000);
                    });

                    const res: any = await pollingCallListAndTotal;

                    return {
                        data: { count: res?.items?.length, items: res?.items, total: res?.total },
                        status: 200,
                    };
                })
                .catch(() => {
                    errorMessage('Failed to fetch data');
                })
                .finally(() => {
                    setQueryInProgress(false);
                });
        },
        [
            currentTenantKey,
            queryFilter.entityType,
            queryFilter.entityId,
            queryFilter.timeRange[0],
            queryFilter.timeRange[1],
        ]
    );

    const getTableDataDemoTenants = useCallback(
        async (startIdx: number, endIdx: number, sortParams: string) => {
            const sortStr = sortParams ? sortParams.split('=')[1] : defaultSort;
            // submit
            const queryPayload = getQueryObject(sortParams, startIdx) as ICallsQuery | null;

            if (!queryPayload) {
                return;
            }

            queryPayload.sort_by = sortStr;

            setQueryInProgress(true);

            return triggerGetCalls(queryPayload)
                .then((res: any) => {
                    return {
                        data: res.data,
                        status: 200,
                    };
                })
                .catch((error) => {
                    if (error.response?.status === 422 || error.response?.status === 400) {
                        errorMessage(
                            `Invalid Query ${error.response?.data?.detail ? ': ' + error.response?.data?.detail : ''}`,
                            {
                                duration: 4,
                            }
                        );
                    } else {
                        errorMessage('Query timeout - please select a shorter time range or simplify your query', {
                            duration: 4,
                        });
                    }
                })
                .finally(() => {
                    setQueryInProgress(false);
                });
        },
        [
            currentTenantKey,
            queryFilter.entityType,
            queryFilter.entityId,
            queryFilter.timeRange[0],
            queryFilter.timeRange[1],
        ]
    );

    function submitClickHandler() {
        const validationObj = validate(queryItems);
        setValidation(validationObj);

        if (validationObj.rows.map((row) => isValidRow(row)).filter((valid) => !valid).length) {
            return;
        }

        setQueryFilter({
            timeRange: timeRangeFromInput,
            entityType: entityTypeFromInput,
            entityId: entityIdFromInput,
        });
        state.tableApi?.gridApi.refreshServerSide({ purge: true });
    }

    return (
        <div className="query-container">
            <div className="query-builder-wrapper">
                <Spinner show={queryBuilderSpinner} size={'small'} />
                {!queryBuilderSpinner && (
                    <div className="query-builder-header">
                        <div className="top-controls">
                            <div className="control">
                                <UiDatePicker
                                    showTime={true}
                                    label={'Time Range'}
                                    value={[
                                        timeRangeFromInput[0] as moment.Moment,
                                        timeRangeFromInput[1] as moment.Moment,
                                    ]}
                                    onCalendarChange={onDateChangeHandler}
                                />
                            </div>
                            <div className="control">
                                <span className="control-label">Entity Type</span>
                                <EntitySelect
                                    value={entityTypeFromInput.trim()}
                                    onChange={setEntityTypeFromInput}
                                    minWidth={235}
                                />
                            </div>
                            <div className="control">
                                <UiInput
                                    onChange={(e: any) => setEntityIdFromInput(e.target.value)}
                                    label={'id'}
                                    value={entityIdFromInput.trim()}
                                    placeholder={'Type an entity ID'}
                                />
                            </div>
                            <div className="control">
                                <UiButton
                                    disabled={queryInProgress}
                                    onClick={submitClickHandler}
                                    text="Search"
                                    type="primary"
                                />
                            </div>
                        </div>
                    </div>
                )}
                {!queryBuilderSpinner && (
                    <QueryBuilder
                        onChange={queryBuilderChanged}
                        onClearAll={onClearAll}
                        endpointData={endpointsData}
                        timeRange={{
                            from_timestamp: moment().subtract(1, 'years').format('X'),
                            to_timestamp: moment().format('X'),
                        }}
                        validation={validation}
                    />
                )}
            </div>
            <div className="query-results-summary"></div>
            <div className="query-results-container">
                <QueryResultsTable
                    getTableData={featureflagMap.athena ? getTableDataWithAthena : getTableDataDemoTenants}
                    entityType={entityTypeFromInput}
                />
            </div>
        </div>
    );
};

import React, { useCallback, useState } from 'react';
import moment from 'moment';

import { CallDetailsModal } from 'sharedComponents/CallDetailsModal/CallDetailsModal';
import { UiAgGridSSRM } from 'sharedComponents/ui-ag-grid/UiAgGridSSRM';
import {
    CellRenderCallContent,
    CellRenderGenericMore,
    CellRenderInfo,
    CellRenderLabelList,
    CellRenderSeverity,
    CellExpandButton,
    CellRenderTimeStamp,
    CellRenderEndpointQuery,
    EndpointListRender,
    CellRenderDetokenizable,
} from 'sharedComponents/ui-ag-grid/customCellRenderers';
import { BASIC_AGGRID_COL_TYPE } from 'sharedComponents/ui-ag-grid/commonOptions';
import { httpGet } from 'general/http-service';
import { IBaseLabel } from 'interfaces/labels.interface';
import { IApiCall } from 'interfaces/apiCall.interface';
import { ICallTableData } from 'interfaces/query.interface';
import { IEntity } from 'api/entitiesApi';
import { callsTableColumns } from 'features/query/QueryResultsTable/colDef';

import 'features/query/QueryResultsTable/QueryResultsTable.scss';
import { useSelector } from 'react-redux';
import { selectCurrentTenantKey } from 'api/slices/appInfoSlice';
import { GridOptions } from '@ag-grid-community/core/dist/cjs/es5/entities/gridOptions';
import { GetRowIdParams } from '@ag-grid-community/core/dist/cjs/es5/entities/iCallbackParams';
import { QUERY_RESULT_LIMIT } from 'features/query/Query';

export interface ITableResMetaData {
    count: number;
    total: number;
    labels?: IBaseLabel[];
}

interface IResultsTableProps {
    getTableData: any;
    entityType: string;
}

export const QueryResultsTable = ({ getTableData, entityType }: IResultsTableProps) => {
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const [modalData, setModalData] = useState<IApiCall>();

    async function getCallItemWithAttributes(item: IApiCall): Promise<IApiCall> {
        if (item && !item.attributes) {
            const callParsedDate = new Date(item.timestamp);
            const timestamp = Math.floor(callParsedDate.getTime() / 1000);
            // TODO: check with BE - how it will work with Athena
            const callAttributesUrl = `organizations/${currentTenantKey}/calls/${item.id}/attributes?call_ts=${moment(
                item.timestamp
            ).valueOf()}&from_timestamp=${timestamp}&to_timestamp=${timestamp + 1}`;
            item.attributes = await httpGet(callAttributesUrl).then((res: any) => {
                return res.data.attributes;
            });
        }
        return item;
    }

    const openModal = useCallback(async (item?: any) => {
        getCallItemWithAttributes(item).then((callItemWithAttributes: IApiCall) => {
            setModalData(callItemWithAttributes);
        });
    }, []);

    const gridOptions: GridOptions = {
        components: {
            cellRenderTimeStamp: CellRenderTimeStamp,
            severity: CellRenderSeverity,
            labelListRender: CellRenderLabelList,
            endpointQueryRender: CellRenderEndpointQuery,
            callContentRender: CellRenderCallContent,
            expandButtonCellRenderer: CellExpandButton,
            moreButtonRender: CellRenderGenericMore,
            cellRenderInfo: CellRenderInfo,
            endpointListRender: EndpointListRender,
            cellRenderDetokenizable: CellRenderDetokenizable,
        },
        columnTypes: {
            // col type that cols inherit from
            basic: BASIC_AGGRID_COL_TYPE,
        },
        getRowId: ({ data }: GetRowIdParams<IApiCall>) => data.id + data.timestamp,
        pagination: true,
        paginationPageSize: 50,
    };

    const prepareCallData = (data: IApiCall[]): ICallTableData[] => {
        return data.map((item, index) => ({
            ...item,
            endpoint: [{ method: item.method, name: item.name }],
            entityId: item.entities?.find((entity: IEntity) => entity.name === entityType)?.value,
            content: {
                requestType: item.request_content_type,
                requestSize: item.request_size,
                responseType: item.response_content_type,
                responseSize: item.response_size,
            },
            callDetails: item,
            more: item,
            currOrg: { key: currentTenantKey },
            labels: (item.service_labels || []).concat(item.endpoint_labels || []),
        }));
    };

    return (
        <div className="query-results-table">
            <UiAgGridSSRM
                columns={callsTableColumns(openModal)}
                options={gridOptions}
                dataMappingFunction={prepareCallData}
                getData={getTableData}
                entityType={entityType}
                cacheBlockSize={QUERY_RESULT_LIMIT}
            />

            {modalData && <CallDetailsModal calls={[modalData]} toggleModal={openModal} />}
        </div>
    );
};

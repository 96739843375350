import { createSelector } from '@reduxjs/toolkit';

import { IServerQueryResponse, tenantApi } from './baseServerApi';
import { EventTypesEnum } from 'enums/eventTypes.enum';
import { typePathMap } from 'features/user/User';
import { IApiCall } from 'interfaces/apiCall.interface';
import { RootState } from 'general/store';
import { IBaseLabel } from 'interfaces/labels.interface';
import { IAlert } from 'interfaces/alert.interface';

interface IMetadataParams {
    eventType: EventTypesEnum;
    eventId: string;
    eventTimestamp: string;
}

interface ICallTimeline {
    id: string;
    event_type: EventTypesEnum;
    name: string;
    timestamp: string;
    icon: string;
    call_path: string;
    is_queryable: boolean;
    status_code: number;
}
interface ITimeline {
    calls_query_execution_id: string;
    items: ICallTimeline[];
    count_before: number;
    count_after: number;
}

interface ICallsTable extends IServerQueryResponse<IApiCall> {
    calls_query_execution_id: string;
}

export const investigateApi = tenantApi.injectEndpoints({
    endpoints: (builder) => ({
        // get alert or calls info (deprecated)
        getMetadata: builder.query<any, IMetadataParams>({
            query: ({ eventType, eventId, eventTimestamp }) =>
                `${typePathMap[eventType]}/${eventId}?${
                    eventType === EventTypesEnum.Call ? `call_ts=${eventTimestamp}` : ''
                }`,
            transformResponse: (data: any, meta, args) => {
                const labels =
                    typePathMap[args?.eventType] === typePathMap[EventTypesEnum.Alert]
                        ? data.labels?.map((label: string) => ({ label })) || []
                        : (data.service_labels || []).concat(data.endpoint_labels || []);
                return { ...data, labels };
            },
        }),
        getAlertInfo: builder.query<any, string>({
            query: (alertId) => `alerts/${alertId}`,
            transformResponse: (data: any, meta, args) => {
                const labels = data.labels?.map((label: string) => ({ label })) || [];
                return { ...data, labels };
            },
        }),
        getTimeline: builder.query<ITimeline, string>({
            query: (url) => `${url}`,
        }),
        getCallsAttributes: builder.query<Record<string, IApiCall> | undefined, string>({
            query: (execId) => `calls/queries/${execId}`,
            transformResponse: (data: IApiCall[], meta, args) => {
                if (!data) {
                    return undefined;
                }
                const mapCalls = data.reduce((acc, call: any) => {
                    return { ...acc, [call.id]: { ...call } };
                }, {});
                return mapCalls;
            },
        }),
        getAlerts: builder.query<ICallsTable, { alertId: string; urlParams: string }>({
            query: ({ alertId, urlParams }) => `alerts/${alertId}/calls${urlParams}`,
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetMetadataQuery,
    useLazyGetTimelineQuery,
    useLazyGetCallsAttributesQuery,
    useLazyGetAlertsQuery,
    useGetAlertInfoQuery,
} = investigateApi;

export const selectInvestigateApiSlice = (state: RootState, execId: string) =>
    investigateApi.endpoints.getCallsAttributes.select(execId)(state);

export const selectCallsAttributesList = createSelector(selectInvestigateApiSlice, ({ data }) => data);

export const selectCallAttributeById = (callId?: string | number) =>
    createSelector(selectCallsAttributesList, (list) => {
        if (!callId) {
            return undefined;
        }
        const call = list?.[callId];
        if (!call) {
            return undefined;
        }
        return { ...call, labels: call?.endpoint_labels || [] };
    });

import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import Spinner from 'sharedComponents/spinner/Spinner';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import { ITimelineEvent } from 'interfaces/user.interface';
import { UiSwitch } from 'sharedComponents/switch/Switch';
import { UiTimeline } from 'features/user/EntityTimeline/UiTimeline/UiTimeline';
import { useLazyGetCallsAttributesQuery, useLazyGetTimelineQuery } from 'api/InvestigateApi';
import { useParams } from 'react-router-dom';
import { errorMessage } from 'general/toast-service';
import { useSelector } from 'react-redux';
import { selectFeatureFlagMap } from 'api/slices/appInfoSlice';
import { IInvestigateQueryParams } from 'features/user/User';

import 'features/user/EntityTimeline/EntityTimeline.scss';

interface ITimelineProps {
    onItemClicked: Function;
    onAlertsOnlyChecked: Function;
    alertsOnly: boolean;
    setCallsAttribute: Function;
}

interface INoMoreData {
    top: boolean;
    bottom: boolean;
}
const getCallsAttributesAthena = (
    triggerGetCallsAttributes: Function,
    callsExecId: string,
    setCallsAttribute: Function
) => {
    const startTime = new Date().getTime();

    let intervalID = setInterval(async () => {
        const windowLocation = document.location;
        const isQueryPage = windowLocation.href.split('/').includes('entity');

        if (!isQueryPage || new Date().getTime() - startTime > 150000) {
            clearInterval(intervalID);
            return {};
        }

        try {
            const callAttributesRes = await triggerGetCallsAttributes(callsExecId).unwrap();

            if (callAttributesRes) {
                setCallsAttribute(callAttributesRes);
                clearInterval(intervalID);
            }
        } catch (error: any) {
            errorMessage(error?.data?.detail);
            clearInterval(intervalID);
            return {};
        }
    }, 2000);
};

export const EntityTimeline = (props: ITimelineProps) => {
    const featureflagMap = useSelector(selectFeatureFlagMap);
    const params: IInvestigateQueryParams = useParams();
    const { entityType, eventType, eventId, eventTimestamp } = params;
    const [triggerGetTimeline] = useLazyGetTimelineQuery();
    const [triggerGetCallsAttributes] = useLazyGetCallsAttributesQuery();
    const [timelineData, setTimelineData] = useState<{
        allData: ITimelineEvent[];
        displayData: ITimelineEvent[];
        itemIds: { [itemId: string]: boolean };
    }>({
        allData: [],
        displayData: [],
        itemIds: {},
    });
    const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [checkedItems, setCheckedItems] = useState<ITimelineEvent[]>([]);
    const [noMoreData, setNoMoreData] = useState<INoMoreData>({ top: false, bottom: false });
    const [loadExtraData, setLoadExtraData] = useInfiniteScroll(getMoreData, '.custom-timeline');
    const [currentAnchor, setCurrentAnchor] = useState<{ id: string; isTop: boolean }>({ id: '', isTop: true });
    const limit = 30;

    useEffect(() => {
        if (entityType && eventId) {
            setIsLoading(true);

            const url = `investigate/timeline?anchor_event_type=${eventType}&anchor_id=${eventId}&anchor_ts=${eventTimestamp}&entity_name=${entityType}&before=${limit}&after=${limit}${
                props.alertsOnly ? '&event_types=Alert' : ''
            }`;
            triggerGetTimeline(url)
                .then((res: any) => {
                    setIsLoading(false);

                    if (res.data.count_before < limit) {
                        setNoMoreData((prev) => ({ ...prev, top: true }));
                    } else {
                        setNoMoreData((prev) => ({ ...prev, top: false }));
                    }
                    if (res.data.count_after < limit) {
                        setNoMoreData((prev) => ({ ...prev, bottom: true }));
                    } else {
                        setNoMoreData((prev) => ({ ...prev, bottom: false }));
                    }

                    const tempData = res.data.items.length ? sortByTimestamp(res.data.items) : [];
                    const itemIds: { [itemId: string]: boolean } = {};
                    tempData.forEach((item: ITimelineEvent) => {
                        itemIds[item.id] = true;
                    });
                    setTimelineData({ allData: tempData, displayData: tempData, itemIds });

                    const selectedEvent = tempData.find((event) => event.id === eventId);
                    if (!selectedEvent) {
                        props.onItemClicked(null);
                    }

                    setIsDataLoaded(true);

                    const { calls_query_execution_id } = res?.data;
                    if (featureflagMap.athena && calls_query_execution_id) {
                        getCallsAttributesAthena(
                            triggerGetCallsAttributes,
                            calls_query_execution_id,
                            props.setCallsAttribute
                        );
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.log(`error getting timeline data:`, err);
                });
        }
    }, [props.alertsOnly]);

    useEffect(() => {
        const anchorElement: any = document.getElementsByClassName(`tl_${currentAnchor.id}`);
        currentAnchor.id &&
            anchorElement[0]?.scrollIntoView({
                behavior: 'auto',
                block: currentAnchor.isTop ? 'start' : 'end',
                inline: 'center',
            });
    }, [timelineData]);

    function getMoreData(isTop: boolean) {
        if (isTop ? noMoreData.top : noMoreData.bottom) {
            setLoadExtraData((prevState: any) => ({ ...prevState, isLoading: false }));
            return;
        }

        setIsLoading(true);
        let timelineEvent;
        let before;
        let after;

        if (isTop) {
            timelineEvent = timelineData.allData[0];
            before = limit;
            after = 0;
        } else {
            timelineEvent = timelineData.allData[timelineData.allData.length - 1];
            before = 0;
            after = limit;
        }
        const anchorEventType = timelineEvent.event_type;
        const anchorId = timelineEvent.id;
        setCurrentAnchor({ id: anchorId, isTop });
        const anchorTs = moment(timelineEvent.timestamp).format('x');

        const url = `investigate/timeline?anchor_event_type=${anchorEventType}&anchor_id=${anchorId}&anchor_ts=${anchorTs}&entity_name=${entityType}&before=${before}&after=${after}${
            props.alertsOnly ? '&event_types=Alert' : ''
        }`;
        triggerGetTimeline(url)
            .then(({ data }: any) => {
                setIsLoading(false);

                let filteredItems = data?.items.filter((newItem: ITimelineEvent) => {
                    // remove duplicate items from list
                    return !timelineData.itemIds[newItem.id];
                });
                filteredItems = filteredItems.length ? sortByTimestamp(filteredItems) : [];
                const itemIds: { [itemId: string]: boolean } = {};
                filteredItems.forEach((item: ITimelineEvent) => {
                    itemIds[item.id] = true;
                });

                setLoadExtraData((prevState: any) => ({ ...prevState, isLoading: false }));
                if (
                    (data.count_before < limit && data.count_after === 0) ||
                    (data.count_before === 0 && data.count_after < limit)
                ) {
                    // Register all data loaded at top/bottom to stop further data loading in that direction
                    isTop
                        ? setNoMoreData((prev) => ({ ...prev, top: true }))
                        : setNoMoreData((prev) => ({ ...prev, bottom: true }));
                }
                setTimelineData((prevData: any) => ({
                    displayData: isTop
                        ? [...filteredItems, ...prevData.displayData]
                        : [...prevData.displayData, ...filteredItems],
                    allData: isTop ? [...filteredItems, ...prevData.allData] : [...prevData.allData, ...filteredItems],
                    itemIds: { ...prevData.itemIds, ...itemIds },
                }));

                const { calls_query_execution_id } = data;
                if (featureflagMap.athena && calls_query_execution_id) {
                    getCallsAttributesAthena(
                        triggerGetCallsAttributes,
                        calls_query_execution_id,
                        props.setCallsAttribute
                    );
                }
            })
            .catch((err: any) => {
                setIsLoading(false);
                setLoadExtraData((prevState: any) => ({ ...prevState, isLoading: false }));
            });
    }

    const onItemSelectHandler = useCallback(
        (timelineItem: ITimelineEvent) => {
            props.onItemClicked(timelineItem.event_type, timelineItem.id, moment(timelineItem.timestamp).valueOf());
        },
        [props.onItemClicked]
    );

    const switchChangeHandler = (checked: boolean) => {
        props.onAlertsOnlyChecked(checked);
    };

    const sortByTimestamp = (arr: ITimelineEvent[]) => {
        const sortedArray = [...arr];
        return sortedArray.sort((a, b) => {
            const aDate = new Date(a.timestamp).getTime();
            const bDate = new Date(b.timestamp).getTime();
            return aDate - bDate;
        });
    };

    return (
        <div className="EntityTimeline">
            <span className="timeline-header">Timeline</span>
            <div className="switch-container">
                <UiSwitch defaultChecked={props.alertsOnly} onChange={switchChangeHandler} text="Alerts only" />
            </div>
            <div className="custom-timeline">
                <UiTimeline
                    timelineData={timelineData.displayData}
                    isLoaded={isDataLoaded}
                    checkedItems={checkedItems}
                    selectedItemId={eventId}
                    onItemClicked={onItemSelectHandler}
                    reachedTimelineStart={noMoreData.top}
                    reachedTimelineEnd={noMoreData.bottom}
                />
            </div>
            <div className={`spinner-wrapper ${isLoading ? '' : 'hidden'}`}>
                <Spinner show />
            </div>
        </div>
    );
};

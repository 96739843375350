import { useParams } from 'react-router-dom';
import { UiAgGridSSRM } from 'sharedComponents/ui-ag-grid/UiAgGridSSRM';
import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { colDef } from 'features/user/AlertCallsTable/colDef';
import {
    CellRenderCallContent,
    CellRenderDetokenizable,
    CellRenderEndpoint,
    CellRenderEndpointUserTable,
    CellRenderInfo,
    CellRenderLabelList,
    CellRenderSeverity,
    CellRenderTimeStamp,
    CellRenderVerticalCenter,
} from 'sharedComponents/ui-ag-grid/customCellRenderers';
import { BASIC_AGGRID_COL_TYPE } from 'sharedComponents/ui-ag-grid/commonOptions';
import { PAGE_SIZE_LIMIT } from 'general/http-service';
import { IApiCall } from 'interfaces/apiCall.interface';
import { IBaseLabel } from 'interfaces/labels.interface';
import { UiIcon } from 'sharedComponents/icon/UiIcon';
import { selectCurrentTenantKey, selectFeatureFlagMap } from 'api/slices/appInfoSlice';
import { CallDetailsModal } from 'sharedComponents/CallDetailsModal/CallDetailsModal';
import { useLazyGetAlertsQuery, useLazyGetCallsAttributesQuery } from 'api/InvestigateApi';
import { errorMessage } from 'general/toast-service';
import { IInvestigateQueryParams } from 'features/user/User';

const gridOptions: any = {
    components: {
        cellRenderTimeStamp: CellRenderTimeStamp,
        severity: CellRenderSeverity,
        labelListRender: CellRenderLabelList,
        endpointRender: CellRenderEndpoint,
        endpointRenderUserTable: CellRenderEndpointUserTable,
        callContentRender: CellRenderCallContent,
        cellRenderInfo: CellRenderInfo,
        cellRenderVerticalCenter: CellRenderVerticalCenter,
        cellRenderDetokenizable: CellRenderDetokenizable,
    },
    columnTypes: {
        // col type that cols inherit from
        basic: BASIC_AGGRID_COL_TYPE,
    },
};

const getEntityIdForCall = (entityType: string, relatedEntities: any) => {
    const relevantEntity = relatedEntities.filter((ent: any) => ent.name === entityType);
    return relevantEntity.length > 0 ? relevantEntity[0].value : 'N/A';
};

const getCallsAttributesAthena = (triggerGetCallsTimeline: Function, callsExecId: string) => {
    const startTime = new Date().getTime();

    let intervalID = setInterval(async () => {
        const windowLocation = document.location;
        const isQueryPage = windowLocation.href.split('/').includes('entity');

        if (!isQueryPage || new Date().getTime() - startTime > 150000) {
            clearInterval(intervalID);
            return {};
        }

        try {
            const callAttributesRes = await triggerGetCallsTimeline(callsExecId).unwrap();

            if (callAttributesRes) {
                clearInterval(intervalID);
            }
        } catch (error: any) {
            errorMessage(error?.data?.detail);
            clearInterval(intervalID);
            return {};
        }
    }, 2000);
};

export const AlertCallsTable = () => {
    const featureflagMap = useSelector(selectFeatureFlagMap);
    const [modalData, setModalData] = useState(undefined);
    const currentTenantKey = useSelector(selectCurrentTenantKey);
    const params: IInvestigateQueryParams = useParams();
    const { entityType, eventType, eventId, eventTimestamp } = params;
    const [triggerGetCallsAlert] = useLazyGetAlertsQuery();
    const [triggerGetCallsAttributes] = useLazyGetCallsAttributesQuery();
    const [execId, setExecId] = useState<string>();

    const openModal = useCallback((item?: any) => {
        setModalData(item);
    }, []);

    const columns = colDef(openModal);

    const getRelatedCalls = useCallback(
        async (startIdx: number, endIdx: number, sortParams: string | undefined) => {
            if (!eventType) {
                return;
            }
            const defaultSort = '&sort_by=desc(timestamp)';
            const sortStr = sortParams ? `&${sortParams}` : defaultSort;
            const urlParams = `?anchor_ts=${eventTimestamp}&limit=${PAGE_SIZE_LIMIT}&offset=${startIdx}${sortStr}`;

            try {
                const data = await triggerGetCallsAlert({ alertId: eventId, urlParams }).unwrap();
                const { calls_query_execution_id } = data;
                if (featureflagMap.athena && calls_query_execution_id) {
                    setExecId(calls_query_execution_id);
                    getCallsAttributesAthena(triggerGetCallsAttributes, calls_query_execution_id);
                }

                return {
                    data,
                    status: 200,
                };
            } catch (e) {
                errorMessage('Failed to fetch data');
            }
        },
        [eventId, featureflagMap]
    );

    const formatTableData = (calls: IApiCall[]) => {
        const allSeqLabels: IBaseLabel[] = [];
        const tableData = calls.map((tableItem: IApiCall) => {
            const callLabels = (tableItem.service_labels || []).concat(tableItem.endpoint_labels || []);
            allSeqLabels.concat(callLabels);

            return {
                ...tableItem,
                entityType,
                entityId: getEntityIdForCall(entityType, tableItem.entities),
                content: {
                    requestType: tableItem.request_content_type,
                    requestSize: tableItem.request_size,
                    responseType: tableItem.response_content_type,
                    responseSize: tableItem.response_size,
                },
                labels: callLabels,
                endpoint: [{ method: tableItem.method, name: tableItem.name || tableItem.endpoint_path }],
                info: <UiIcon name="info" onClick={() => openModal(tableItem)} />,
            };
        });
        return tableData;
    };

    return (
        <div className="user-table">
            <UiAgGridSSRM
                options={gridOptions}
                columns={columns}
                getData={getRelatedCalls as (args: any) => Promise<AxiosResponse>}
                dataMappingFunction={formatTableData}
            />
            {modalData && <CallDetailsModal calls={[modalData]} toggleModal={openModal} execId={execId} />}
        </div>
    );
};

import { ICellEditorParams, ICellRendererParams, RowNode } from '@ag-grid-community/core';
import { Select } from 'antd';
import moment from 'moment';
import React, { Component, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import { saveAs } from 'file-saver';

import { errorMessage } from 'general/toast-service';
import { httpGet } from 'general/http-service';
import { ICommentsResponse, useGetAlertCommentsQuery } from 'api/alertApi';
import { icons } from 'assets/icons';
import { AlertSeverityEnum } from 'features/alerts/Alerts';
import Spinner from '../spinner/Spinner';
import {
    bulletSpaceFormat,
    dateStringFormat,
    decodeHTMLChars,
    extractErrorMessage,
    getDiffFromNowInDays,
    formatNumberWithSeparators,
    patchAlert,
    removeTrailingSlashes,
    TimeDisplayResEnum,
    timeStringFormat,
} from 'general/utils';
import { useVirtualScroll } from 'hooks/useInfiniteScroll';
import { IAlert } from 'interfaces/alert.interface';
import { ILabel } from 'interfaces/labels.interface';
import { ApiLabel } from '../ApiLabel/ApiLabel';
import { UiButton } from '../button/Button';
import { CommentAddPopover } from '../CommentAddPopover/CommentAddPopover';
import { CommentCard } from '../CommentCard/CommentCard';
import { CommentCount } from '../CommentCount/CommentCount';
import { UiIcon } from '../icon/UiIcon';
import { UiLabel } from '../label/UiLabel';
import { UiSwitch } from '../switch/Switch';
import { UiColumnProgress } from '../UiColumnProgress/UiColumnPrgress';
import { UiOverlayMenu } from '../UiOverlayMenu/UiOverlayMenu';
import { UiTitle } from '../UiTitle/UiTitle';
import { UiTooltip } from '../UiTooltip/UiTooltip';
import { Detokenizable } from 'sharedComponents/Detokenizable/Detokenizable';
import { IDiscoveryService } from 'interfaces/service.interface';
import { IAvailableAction } from 'api/automatedActionRulesApi';
import { Severity } from 'sharedComponents/Severity/Severity';

import './customCellRenderers.scss';

const { Option } = Select;

export const CellRenderSeverity = ({
    value,
    alertSeveritiesPromise,
    data,
}: {
    value: AlertSeverityEnum | null | undefined;
    alertSeveritiesPromise: Promise<string[]>;
    data: any;
}) => {
    const [val, setVal] = useState(value);
    const [iconName, setIconName] = useState(`alert${val}`);
    const [severityValues, setSeverityValues] = useState<string[]>([]);
    const popoverRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        alertSeveritiesPromise.then((values: string[]) => {
            setSeverityValues(values);
        });
    }, [alertSeveritiesPromise]);

    const changeHandler = (e: AlertSeverityEnum) => {
        patchAlert('severity', data.currOrg, data.id, e as AlertSeverityEnum).then(() => {
            setVal(e);
            setIconName(`alert${e}`);
            popoverRef?.current?.click();
        });
    };

    const onClose = () => {
        data.refreshDataCB();
    };

    if (!val || !iconName) {
        return null;
    }
    return (
        <div className="CellRenderSeverity">
            <CommentAddPopover onClose={onClose} alert={data as IAlert}>
                <div ref={popoverRef}></div>
            </CommentAddPopover>

            <Select
                defaultValue={value}
                suffixIcon={icons['treeOpen']()}
                style={{ width: '120px' }}
                onChange={changeHandler}
            >
                {severityValues.map((severity: string) => {
                    return (
                        <Option value={severity} key={`${data?.id} ${severity}`}>
                            <div style={{ display: 'flex', alignItems: 'center', height: '32px' }}>
                                <UiIcon name={`alert${severity}`} />
                                <span style={{ paddingLeft: '0.5rem', paddingTop: '2px' }}>{severity}</span>
                            </div>
                        </Option>
                    );
                })}
            </Select>
        </div>
    );
};

export const CellRenderSeverityBasic = ({ value }: ICellRendererParams) => {
    return (
        <div className="CellRenderSeverity">
            <div className="inner-container">
                <Severity severity={value} />
            </div>
        </div>
    );
};

const DISPLAYED_LABELS = 3;

export const CellRenderLabelList = (params: ICellRendererParams) => {
    return <RenderLabelList labels={params.value || []} />;
};

export const RenderLabelList = ({ labels = [] }: { labels: { label: string }[] }) => {
    const totalLabels = labels.length;

    if (totalLabels < 0) return null;

    const toolTipText = labels
        .map(({ label }: { label: string }) => label)
        .filter((labelText: string) => !!labelText)
        .join(', ');
    const moreStr = totalLabels > DISPLAYED_LABELS ? `+ ${totalLabels - DISPLAYED_LABELS} more` : '';

    return (
        <div className="custom-cell-renderer labels-renderer">
            <UiTooltip title={toolTipText} placement={'top'}>
                <div className="line line-1">
                    {labels[0]?.label && <UiLabel text={labels[0].label} />}
                    {labels[2]?.label && <UiLabel text={labels[2].label} />}
                </div>
                <div className="line line-2">
                    {labels[1]?.label && <UiLabel text={labels[1].label} />}
                    <div className="more">{moreStr}</div>
                </div>
            </UiTooltip>
        </div>
    );
};

export const CellRenderEndpointUserTable = (params: ICellRendererParams) => {
    const endpointPath = params.data.endpoint_path;
    const method = params.data.method;

    return (
        <div className="custom-cell-renderer endpoint-renderer">
            <ApiLabel apiType={[method]} key={method + endpointPath} place="top" apiName={endpointPath} />
        </div>
    );
};

export const CellRenderEndpointQuery = (params: ICellRendererParams) => {
    const endpointPath = params.data.endpoint_path;
    const method = params.data.method;
    return (
        <div className="custom-cell-renderer endpoint-renderer">
            <ApiLabel apiType={[method]} key={method + endpointPath} place="top" apiName={endpointPath} />
        </div>
    );
};

export const CellRenderEndpointPreview = (params: ICellRendererParams) => {
    return (
        <div className="custom-cell-renderer endpoint-renderer">
            <ApiLabel apiType={params.data.methods} key={params.data.path} place="top" apiName={params.data.path} />
        </div>
    );
};

export const CellRenderEndpoint = (params: ICellRendererParams) => {
    // Expects params.data.endpoints to be an array of {method: string; endpoint_path: string;}
    return (
        <div className="custom-cell-renderer endpoint-renderer">
            {params.data.endpoints.length > 0 && (
                // When we have alert with no endpoints or more than 1 endpoint we get empty array
                <ApiLabel
                    apiType={[params.data.endpoints[0].method]}
                    key={params.data.endpoint}
                    place="top"
                    apiName={params.data.endpoints[0].endpoint_path}
                />
            )}
        </div>
    );
};

export const EndpointListRender = (params: ICellRendererParams) => {
    return (
        <div className="custom-cell-renderer endpoint-renderer">
            {params.data.endpoints.map((endpoint: { method: string; name: string }) => {
                return (
                    <ApiLabel
                        apiType={[endpoint.method]}
                        key={endpoint.method + endpoint.name}
                        place="top"
                        apiName={endpoint.name}
                    />
                );
            })}
        </div>
    );
};

export class CellRenderCallContent extends Component {
    private readonly value: any;

    constructor(props: any) {
        super(props);
        this.value = props.value;
    }

    render() {
        return (
            <div>
                <div className="ellipsis">
                    &#x2192;{this.value?.requestType}&nbsp;({this.value?.requestSize})
                </div>
                <div className="ellipsis">
                    &#x2190;{this.value?.responseType}&nbsp;({this.value?.responseSize})
                </div>
            </div>
        );
    }
}

export const CellRenderInfo = (props: any) => {
    return (
        <div className={'custom-cell-renderer table-more-button'}>
            <UiIcon name="info" onClick={() => props.openModalCb(props.data)} />
        </div>
    );
};

export const CellRenderTimeStamp = (props: any) => {
    const timeResolution = props.timeResolution || TimeDisplayResEnum.SEC;

    if (props?.data?.hide) {
        return null;
    }

    if (!props.value) {
        return '-';
    }

    return (
        <div className={`custom-cell-renderer ${props.displaySingleLine ? 'no-wrap' : 'pre-wrap'}`}>
            {`${dateStringFormat(props.value)}${props.displaySingleLine ? ' | ' : '\n'}${timeStringFormat(
                props.value,
                timeResolution
            )}`}
        </div>
    );
};

export const CellRenderCustomLoading = (props: any) => {
    return '';
};

export const CustomNoRowsOverlay = (props: any) => {
    return (
        <div style={{ width: '80px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 466.482 466.482">
                <g fill="#D6DEF4">
                    <path d="M233.824 148.642L126.673 119.94c-4.365-1.187-8.795 1.422-9.958 5.747-1.162 4.333 1.414 8.795 5.747 9.957l107.151 28.702a8.228 8.228 0 002.105.276c3.593 0 6.877-2.398 7.844-6.023a8.103 8.103 0 00-5.738-9.957zM257.795 160.591c.301-.423.553-.886.935-1.244-.374.366-.626.821-.935 1.244zM256.933 161.867c-.187.415-.236.878-.35 1.333.114-.447.163-.91.35-1.333zM261.42 157.665c-.585.219-1.065.585-1.569.927.504-.334.984-.7 1.569-.927z" />
                    <path d="M256.616 398.588a8.126 8.126 0 015.072-10.315l10.754-3.666V170.996l-5.462 1.894a8.107 8.107 0 01-10.34-5.015c-.333-.967-.431-1.951-.398-2.918 0 .089-.049.171-.049.26V385.51L86.729 342.265v-220.91l-3.967 1.301-12.29 11.055v214.863c0 3.715 2.52 6.95 6.121 7.877l185.722 47.39a8.078 8.078 0 002.008.252c-3.399.006-6.561-2.124-7.707-5.505zM264.306 404.099c.902 0 1.788-.146 2.634-.439h-.008a8.013 8.013 0 01-2.626.439zM74.032 108.918c.553-.382 1.105-.772 1.74-1.008-.651.235-1.187.618-1.74 1.008zM414.823 111.429c-.098-.171-.268-.268-.374-.423.797 1.187 1.357 2.544 1.439 4.048a8.126 8.126 0 01-5.454 8.096l-6.373 2.211 42.374 74.003-116.19 40.245-32.474-46.829c-2.561-3.674-7.633-4.609-11.315-2.04-3.69 2.552-4.601 7.625-2.048 11.315l35.985 51.893a8.156 8.156 0 006.682 3.495c.886 0 1.788-.138 2.658-.447l65.411-22.662v-11.421c0-4.495 3.642-8.129 8.129-8.129s8.129 3.633 8.129 8.129v5.788l49.617-17.184a8.125 8.125 0 004.95-4.836 8.148 8.148 0 00-.561-6.893l-50.585-88.359zM412.384 108.845c.276.195.504.431.748.65-.252-.22-.48-.464-.748-.65zM409.775 107.617c.244.065.455.203.691.285-.244-.082-.447-.22-.691-.285z" />
                    <path d="M395.151 342.761l-122.717 41.846v11.356a8.126 8.126 0 01-5.495 7.69l138.958-47.39a8.129 8.129 0 005.503-7.69V228.7l-16.257 5.633V342.76h.008z" />
                    <path d="M272.434 395.97v-11.356l-10.754 3.666a8.126 8.126 0 00-5.072 10.315 8.13 8.13 0 007.69 5.511c.87 0 1.756-.138 2.626-.439h.008a7.929 7.929 0 002.349-1.268 8.159 8.159 0 003.153-6.429zM403.28 214.784a8.126 8.126 0 00-8.129 8.129v11.421l16.257-5.633v-5.788a8.126 8.126 0 00-8.128-8.129zM413.132 109.503c.488.439.935.935 1.317 1.504a7.77 7.77 0 00-1.317-1.504zM74.032 108.918c-.285.195-.626.284-.878.512L2.696 172.808a8.12 8.12 0 00-1.52 10.25 8.125 8.125 0 006.958 3.918c.943 0 1.902-.171 2.837-.504l43.09-16.046a8.122 8.122 0 004.78-10.453c-1.569-4.219-6.251-6.332-10.453-4.788l-3.048 1.138 25.134-22.606v-18.249c-.019-2.762 1.453-5.087 3.558-6.55zM410.466 107.902c.675.236 1.325.528 1.918.943-.585-.407-1.236-.7-1.918-.943zM86.713 115.469v5.893l129.293-42.431 162.571 38.058-116.937 40.554c-.081.024-.138.098-.219.13.902-.341 1.861-.577 2.878-.577a8.126 8.126 0 018.129 8.129v5.779l131.626-45.642-3.349-5.844c-2.227-3.894-.878-8.868 3.016-11.095 1.91-1.081 4.072-1.292 6.048-.797-.057-.016-.098-.049-.154-.065l-192.13-44.976a8.3 8.3 0 00-4.389.187L76.048 107.747c-.106.033-.179.122-.285.163.886-.333 1.821-.569 2.821-.569a8.121 8.121 0 018.129 8.128z" />
                    <path d="M258.73 159.347c.325-.309.748-.504 1.122-.756-.375.252-.797.447-1.122.756zM256.226 164.956c.016-.618.203-1.179.358-1.756-.155.586-.334 1.146-.358 1.756zM257.795 160.591c-.301.423-.65.805-.862 1.276.22-.471.569-.853.862-1.276zM86.713 121.354v-5.893a8.127 8.127 0 00-8.129-8.129c-1 0-1.935.236-2.821.569-.634.236-1.187.626-1.74 1.008-2.105 1.471-3.568 3.788-3.568 6.552v18.249l12.29-11.055 3.968-1.301z" />
                    <path d="M264.306 157.088c-1.024 0-1.983.236-2.878.577-.585.228-1.073.585-1.569.927-.374.252-.788.447-1.122.756-.382.366-.634.821-.935 1.244s-.642.805-.862 1.276c-.187.423-.236.886-.35 1.333-.154.577-.333 1.138-.358 1.756-.033.967.057 1.951.398 2.918a8.112 8.112 0 0010.34 5.015l5.462-1.894v-5.779a8.123 8.123 0 00-8.126-8.129zM403.727 108.414c-3.894 2.227-5.243 7.202-3.016 11.096l3.349 5.844 6.373-2.211a8.133 8.133 0 005.454-8.096c-.081-1.504-.642-2.853-1.439-4.048a7.803 7.803 0 00-1.317-1.504c-.244-.228-.48-.463-.748-.65a8.122 8.122 0 00-1.918-.943c-.236-.081-.439-.219-.691-.285-1.975-.504-4.137-.293-6.047.797z" />
                </g>
            </svg>
            <span className={''}>No Data</span>
        </div>
    );
};

export const CellRenderGenericMore = (props: any) => {
    if (props?.hideMenu) {
        return null;
    }
    return (
        <div className={`custom-cell-renderer table-more-button`}>
            <UiOverlayMenu menuItems={props.menuItems} />
        </div>
    );
};

export const CellRenderAlertMore = (props: any) => {
    const popoverRef = useRef<HTMLDivElement>(null);
    const closeItem = props.menuItems.find((item: any) => item.id === 'close-open-alert');

    closeItem.onClick = () => {
        closeItem.clickHandler().then(() => {
            popoverRef?.current?.click();
        });
    };

    return (
        <div className={`custom-cell-renderer table-more-button`}>
            <CommentAddPopover
                onClose={() => {
                    closeItem.resolveHandler();
                }}
                alert={props.data as IAlert}
            >
                <div ref={popoverRef}></div>
            </CommentAddPopover>

            <UiOverlayMenu menuItems={props.menuItems} />
        </div>
    );
};

interface ICellMenuProps extends ICellRendererParams {
    menuItems: any;
    id?: string;
    hidden?: boolean;
}

export const CellRenderRangeSensitiveMore = (props: ICellMenuProps) => {
    return (
        <>
            {!props.hidden && (
                <div className={`custom-cell-renderer table-more-button`}>
                    <UiOverlayMenu
                        menuItems={props.menuItems}
                        handleClickMenu={() => props.api.deselectAll()}
                        menuId={props.id}
                    />
                </div>
            )}
        </>
    );
};

export const CellExpandButton = (props: ICellRendererParams) => {
    const { api, rowIndex } = props;

    const clickHandler = useCallback(() => {
        api.forEachNode((node: RowNode) => {
            if (node.rowIndex === rowIndex) {
                node.setExpanded(!node.expanded);
            } else {
                node.setExpanded(false);
            }
        });
    }, [api, rowIndex]);

    return (
        <div className={'custom-cell-renderer cell-expand-button'} onClick={clickHandler}>
            <UiIcon name="rightArrow" />
        </div>
    );
};

export const DescriptionRender = (props: any) => {
    return (
        <UiTooltip title={props.value} overlayClassName="pre-wrap" placement={'top'}>
            <div className="pre-wrap custom-cell-renderer description-renderer ">{props.value}</div>
        </UiTooltip>
    );
};

export const CellRenderVerticalCenter = (props: any) => {
    const value = Array.isArray(props.value) ? props.value[0] : props.value;
    const ValueOrNumber =
        props?.colDef?.filter === 'agNumberColumnFilter' ? formatNumberWithSeparators.format(value) : value;
    return (
        <UiTooltip title={value} overlayClassName="pre-wrap" placement={'top'}>
            <div className="custom-cell-renderer vertical-center">{ValueOrNumber}</div>
        </UiTooltip>
    );
};

export const CellRenderDetokenizable = (props: any) => {
    const value = Array.isArray(props.value) ? props.value[0] : props.value;

    return (
        <Detokenizable
            tokenizedContent={value}
            renderTooltip={(content) => content}
            renderContent={(content) => (
                <div className="detokenizable-cell-renderer custom-cell-renderer vertical-center">{content}</div>
            )}
        />
    );
};

export const CellRenderedCommentsCount = (value: ICellRendererParams) => (
    <div className="custom-cell-renderer comment-count">
        <CommentCount showBasedOnValue value={value.data.comments_count} />
    </div>
);

export const TableDetailCelRenderer = (props: any) => {
    const DEFAULT_SIZE = 30;
    const [page, setPage] = useState(1);
    const scrollRef = useRef<HTMLDivElement>(null);
    const { data, isLoading, isFetching }: ICommentsResponse = useGetAlertCommentsQuery({
        alertId: props.data.id,
        limit: page * DEFAULT_SIZE,
        offset: 0,
    });

    useEffect(() => {
        if (data?.total) {
            const node = props.node as RowNode;
            node.setDataValue('comments_count', data.total);
        }
    }, [data?.total, props.node]);

    useVirtualScroll(() => {
        if (Math.ceil((data?.total ?? 0) / DEFAULT_SIZE) > page) {
            setPage((pg) => pg + 1);
        }
    }, scrollRef);

    const addCommentSection = (
        <CommentAddPopover alert={props.data}>
            <div className={`add-comment-button ${(data?.total ?? 0) >= 200 ? 'disabled-btn' : ''}`}>
                <UiIcon name="add" />
                <UiButton className="add-comment-btn" type="text" text="Add comment" />
            </div>
        </CommentAddPopover>
    );

    return (
        <div className="custom-cell-renderer table-row-detail-panel">
            <div className="section desc pre-wrap">
                <div className="section-header">
                    <UiTitle title="Description" type="medium" />
                </div>
                <Detokenizable
                    className="section-content"
                    tokenizedContent={props.data.description}
                    renderTooltip={(content) => decodeHTMLChars(bulletSpaceFormat(content)) || 'N/A'}
                    renderContent={(content) => decodeHTMLChars(bulletSpaceFormat(content)) || 'N/A'}
                />
            </div>
            <div className="section more-info">
                <div className="section-header">
                    <UiTitle title="More Info" type="medium" />
                </div>
                <div className="section-content">
                    {decodeHTMLChars(bulletSpaceFormat(props.data.alert_info)) || 'N/A'}
                </div>
            </div>
            <div className="section recommendations">
                <div className="section-header">
                    <UiTitle title="Recommendations" type="medium" />
                    {!props.data.comments_count ? addCommentSection : null}
                </div>
                <div className="section-content">
                    {decodeHTMLChars(bulletSpaceFormat(props.data.recommendations)) || 'N/A'}
                </div>
            </div>
            {!!props.data.comments_count && (
                <div
                    onContextMenu={(e) => {
                        e.stopPropagation();
                    }}
                    className={`section comments ${props.data.comments_count ? '' : 'hide-section'}`}
                >
                    <div className="section-header">
                        <UiTitle title={`Comments (${data?.total ?? 0})`} type="medium" />
                        {addCommentSection}
                    </div>
                    {(isLoading || isFetching) && <Spinner show={true} />}
                    <div ref={scrollRef} className="section-content">
                        {!data?.items.length && !isLoading && !isFetching && 'no data'}
                        {data?.items.map((item) => (
                            <CommentCard alertId={props.data.id} key={`comment-${item.id}`} comment={item} />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export const ServiceFilterCellRenderer = (params: any) => {
    const strLength = params.value.length;
    const displayLength = 40;
    const secondPartStartIdx = Math.max(strLength - displayLength / 2, displayLength / 2 + 1);
    const displayVal =
        strLength < displayLength
            ? params.value
            : `${params.value.substring(0, displayLength / 2)}...${params.value.substring(secondPartStartIdx)}`;
    return displayVal;
};

export const CellRenderToggle = (props: ICellRendererParams & { onRowToggleHandler: Function; disabled?: boolean }) => {
    if (props?.data?.hide) {
        return null;
    }
    return (
        <div className="CellRenderToggle">
            <UiSwitch
                disabled={props.disabled}
                defaultChecked={props.value}
                onChange={(e: boolean) => props.onRowToggleHandler(e, props.rowIndex, props.data.id)}
            />
        </div>
    );
};

// Ag grid custom table header
export const TableColumnHeader = (props: any) => {
    const [ascSort, setAscSort] = useState<string>('inactive');
    const [descSort, setDescSort] = useState<string>('inactive');
    const [noSort, setNoSort] = useState<string>('inactive');
    const refButton = useRef<HTMLDivElement>(null);

    const onMenuClicked = () => {
        props.showColumnMenu(refButton.current);
    };

    const onSortChanged = () => {
        if (props.column.sort === 'desc') setAscSort(props.column.isSortAscending() ? 'active' : 'inactive');
        setDescSort(props.column.isSortDescending() ? 'active' : 'inactive');
        setNoSort(!props.column.isSortAscending() && !props.column.isSortDescending() ? 'active' : 'inactive');
    };

    useEffect(() => {
        props.column.addEventListener('sortChanged', onSortChanged);
        onSortChanged();
    }, []);

    let filter: JSX.Element | null = null;
    if (props.enableMenu) {
        filter = props.column.colDef.filter && (
            <div ref={refButton} className="customFilterButton header-button" onClick={() => onMenuClicked()}>
                <UiIcon name="filter" isNotDefaultStyle />
            </div>
        );
    }

    let sort: JSX.Element | null = null;
    if (props.enableSorting) {
        const hasSort = props.column.getSort();
        sort = (
            <div className="customSortButton header-button" onClick={(e) => props.progressSort(e.shiftKey)}>
                {hasSort === 'asc' ? (
                    <UiIcon name="sortAscending" isNotDefaultStyle />
                ) : hasSort === 'desc' ? (
                    <UiIcon name="sortDescending" isNotDefaultStyle />
                ) : (
                    <UiIcon name="sortUnSort" isNotDefaultStyle />
                )}
            </div>
        );
    }

    const headerLabel = (
        <>
            {props.prefixIconName && <UiIcon name={props.prefixIconName} />}
            <span className="customHeaderLabel-content">{props.displayName}</span>
        </>
    );

    return (
        <div className="header-cell-container" style={{ width: props.width }}>
            <div className="customHeaderLabel">
                {props.headerTooltipContent ? (
                    <UiTooltip
                        disableCopyButton={!!props.disableCopyButton}
                        title={props.headerTooltipContent}
                        delay={props.tooltipShowDelay / 1000 || 0.5}
                    >
                        {headerLabel}
                    </UiTooltip>
                ) : (
                    headerLabel
                )}
            </div>
            <div className="spacer" />
            {sort ? sort : ''}
            {filter ? filter : ''}
        </div>
    );
};

export const clipboardHeadersCopy = (params: any) => {
    return params?.value;
};

export const clipboardCopyForAllColTypes = (params: any) => {
    // if no selected row return just the value
    if (params.api.getSelectedNodes().length < 1) {
        return params.value;
    } else {
        let returnVal = '';

        switch (params.column.colId) {
            case 'timestamp': {
                returnVal = `${dateStringFormat(params.value)} | ${timeStringFormat(
                    params.value,
                    TimeDisplayResEnum.MS
                )}`;
                break;
            }
            case 'labels': {
                if (Array.isArray(params.value)) {
                    returnVal = params.value.map((label: ILabel) => label.text).join();
                } else {
                    returnVal = '';
                }
                break;
            }
            case 'endpoint': {
                returnVal = params.value.map((endpoint: any) => `[${endpoint.method}]${endpoint.endpoint_path}`);
                break;
            }
            case 'endpoints': {
                returnVal = params.value.map((endpoint: any) => `[${endpoint.method}]${endpoint.endpoint_path}`);
                break;
            }
            case 'content': {
                returnVal = `Request Size: ${params.value.requestSize}\nResponse Type: ${
                    params.value.responseType || 'n/a'
                }\nResponse Size: ${params.value.responseSize}`;
                break;
            }
            case 'overlay': {
                returnVal = '';
                break;
            }
            case 'info': {
                returnVal = '';
                break;
            }
            default: {
                returnVal = params?.value;
            }
        }
        if (params.column.colDef.headerName) {
            return `\n${params.column.colDef.headerName}: ${returnVal}\n`;
        }
    }
};

const getCellValueContent = (changeValue: number) => {
    return (
        <div className="ep-change-cell-content-wrapper">
            <span className="value">
                {changeValue > 0 ? '+' : ''}
                {changeValue}
            </span>
            {changeValue !== 0 && <UiIcon name={changeValue > 0 ? 'upArrowRed' : 'downArrowGreen'} />}
        </div>
    );
};

export const cellRenderEndpointsChange = (props: ICellRendererParams) => {
    const getTooltipContent = (epChangeList: string[]) => {
        return (
            <ul className="ep-change-list-tooltip" key={props?.data?.name}>
                {epChangeList?.map((ep: string) => (
                    <li key={ep}>{ep}</li>
                ))}
            </ul>
        );
    };

    return (
        <div className="custom-cell-renderer">
            {props.data?.endpoints_change_list?.length > 0 ? (
                <UiTooltip title={getTooltipContent(props.data.endpoints_change_list)} placement={'topLeft'}>
                    {getCellValueContent(props.value)}
                </UiTooltip>
            ) : (
                getCellValueContent(props.value)
            )}
        </div>
    );
};

export const cellRenderRiskChange = (props: ICellRendererParams) => {
    return <div className="custom-cell-renderer">{getCellValueContent(props.value)}</div>;
};

export const CellProgressView = (params: any) => {
    return <UiColumnProgress score={params.data.risk_score} />;
};

export const CellDateView = (params: any) => {
    return (
        <UiTooltip
            title={
                !!params.data?.calls_count
                    ? moment(params.value).format('D MMMM YYYY, HH:mm:ss')
                    : 'Endpoint is not in time range'
            }
        >
            <span>{params.value ? moment(params.value).format('D MMMM YYYY, HH:mm:ss') : 'No date available'}</span>
        </UiTooltip>
    );
};

export const CellProgressBarView = (score: number) => {
    return <UiColumnProgress score={score} />;
};

export const CellRedirectTitleService = (params: any, option: string) => {
    const history = useHistory();
    const pathnameWithoutTrailingSlashes = removeTrailingSlashes(history.location.pathname);
    let responseTitle: string = '';
    let path: string;
    if (option === 'SERVICE') {
        path = `${pathnameWithoutTrailingSlashes}/${encodeURIComponent(params.data.name)}/${history.location.search}`;
        responseTitle = (params.data.method ? params.data.method + ' ' : '') + params.data.name;
    } else {
        path = `${pathnameWithoutTrailingSlashes}/endpoints/${encodeURIComponent(params.data.id)}/${
            history.location.search
        }`;
        responseTitle = (params.data.method ? params.data.method + ' ' : '') + params.data.endpoint_path;
    }

    const isInTimeRange = !!params?.data?.calls_count;

    if (option === 'ENDPOINT')
        return (
            <ApiLabel
                apiType={[params.data.method]}
                apiName={params.data.endpoint_path}
                tooltip={params.data.endpoint_path}
                path={isInTimeRange ? path : ''}
                disable={!isInTimeRange}
                pendingDeletion={params.data?.pending_deletion}
                deletedBy={params.data?.deleted_by}
            />
        );

    return (
        <UiTooltip title={responseTitle}>
            {isInTimeRange || option === 'SERVICE' ? (
                <NavLink className="custom-title" to={path}>
                    {responseTitle}
                </NavLink>
            ) : (
                <span> {responseTitle} </span>
            )}
        </UiTooltip>
    );
};

interface IHoverIconCellEditorParams extends ICellEditorParams {
    isShow: Function;
    onClick: (params: any) => {};
    icon: string;
    tooltipMessage: string;
}

export const CellRenderHoverIcon = (params: IHoverIconCellEditorParams) => {
    return params.isShow && params.isShow(params.data) ? (
        <UiTooltip title={params.tooltipMessage} disableCopyButton>
            <div
                onClick={(e) => {
                    params.onClick(params);
                }}
                className="custom-cell-renderer hover-icon"
            >
                <UiIcon name={params.icon} />
            </div>
        </UiTooltip>
    ) : (
        <></>
    );
};

export const CellRenderStatus = (props: any) => {
    return (
        <div className="status-container">
            <span className={`status-dot status-dot-${props.value}`}></span>
            {props.value}
        </div>
    );
};

export const cellRenderTimestampTooltip = (props: any) => {
    if (!props.timestamp) {
        return <UiTooltip title="-">-</UiTooltip>;
    }

    return (
        <UiTooltip
            title={
                props.maxDiffDays && getDiffFromNowInDays(props.timestamp) > props.maxDiffDays
                    ? ''
                    : moment(props.timestamp).format('MMM DD yyyy hh:mm:ss')
            }
        >
            {props.value}
        </UiTooltip>
    );
};

export const totalAndActiveEndpoints = (a: unknown, service: IDiscoveryService) => {
    return (
        <div className="TotalAndActiveEndpoints">
            <span className="active-endpoints-counter">
                {formatNumberWithSeparators.format(service.active_endpoints_count ?? 0)}
            </span>{' '}
            /{' '}
            <span className="total-endpoints-counter">
                {formatNumberWithSeparators.format(service.total_endpoints_count ?? 0)}
            </span>
        </div>
    );
};

export const CellRenderScanStatus = (props: { value: string; path?: string }) => {
    const statusContent = (
        <div className="scan-status-cell">
            <UiIcon name={props.value.toLowerCase()} className={props.value.toLowerCase()} />
            <span>{props.value}</span>
        </div>
    );

    if (props.path) {
        return <CellRenderLink path={props.path} text={statusContent} />;
    }

    return statusContent;
};

export const CellRenderDownloadLink = ({ url, text }: { url: string; text: string }) => {
    const onClick = async () => {
        try {
            const response = await httpGet(url);
            const blob = new Blob([response.data.data], { type: 'text/plain;charset=utf-8' });
            saveAs(blob, text);
        } catch (error: unknown) {
            errorMessage(extractErrorMessage(error));
        }
    };

    return (
        <UiTooltip title={text}>
            <a onClick={onClick}>{text}</a>
        </UiTooltip>
    );
};

export const CellRenderLink = ({ path, text }: { path: string; text: string | JSX.Element }) => {
    return (
        <NavLink className="custom-title" to={path}>
            {text}
        </NavLink>
    );
};

export const CellRenderActionDescription = ({ action }: { action?: IAvailableAction }) => {
    return (
        <UiTooltip title={action?.description} overlayClassName="pre-wrap" placement={'top'}>
            <div className="custom-cell-renderer vertical-center">{action?.name}</div>
        </UiTooltip>
    );
};
